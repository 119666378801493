import { FeedbackAnalyticsData } from '~/interfaces/FeedbackFormData';
import { analytics } from './useLoadSegment';

export function fireScheduleTemplateSelected({ timeSplit, pattern }: { timeSplit: number; pattern: number }) {
  analytics.track('Schedule Template Selected', { timeSplit, pattern });
}

interface ScheduleExportActioned {
  email: string;
  userId?: string;
}

export function fireExportConfirmed({ email, userId }: ScheduleExportActioned) {
  analytics.track('Schedule Export Actioned', { email, userId, type: 'ics', action: 'confirmed' });
}

export function fireFeedbackFormSubmitted(payload: FeedbackAnalyticsData) {
  analytics.track('Feedback Form Submitted', payload);
}

export function fireCalendarPublished(email: string) {
  analytics.track('Calendar Link Created', { email });
}
